(function(){

  var savedScroll = 0;

  document.addEventListener("turbo:submit-start", function(event) {
    if (event.target.hasAttribute('data-turbo-preserve-scroll')) {
      savedScroll = document.documentElement.scrollTop;
    } else {
      savedScroll = 0;
    }
  });

  document.addEventListener("turbo:render", function(event) {
    setTimeout(function () {
      if(savedScroll > 0) {
        document.documentElement.scrollTop = savedScroll;
      }
    }, 100);
  });

})();
