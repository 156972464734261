import { Controller } from "@hotwired/stimulus"
import * as amplitude from '@amplitude/analytics-browser';

amplitude.init(
  document.body.dataset['aak'],
  {defaultTracking: {attribution: false} }
);

export default class extends Controller {
  static targets = [
    'email',
    'userInfo',
    'doctor',
    'menuOption',
    'searchTerm',
  ]

  connect() {
  }

  // Events
  patientOpensQR() {
    amplitude.track('Patient opens QR', {
      'Doctor id': this.doctorTarget.dataset['doctorId'],
      'Doctor name': this.doctorTarget.dataset['doctorName'],
    });
  }

  patientSubmitsEmail() {
    amplitude.track('Patient submits email', {
      email: this.emailTarget.value
    });
  }

  userRequestsSessionLink() {
    amplitude.track('User requests login link', {
      email: this.emailTarget.value
    });
  }

  createUserSession() {
    // identify user id and group (doctor or patient)
    amplitude.setGroup('Role', this.userInfoTarget.dataset['role']);
    amplitude.setUserId(this.userInfoTarget.dataset['userId']);
    // set user's full name
    const sessionCreator = new amplitude.Identify();
    sessionCreator.setOnce('Full name', this.userInfoTarget.dataset['fullName']);
    // set specialty if exists(for patients role)
    if(this.userInfoTarget.dataset['doctorSpecialty'] !== null && this.userInfoTarget.dataset['doctorSpecialty'] !== ''){
      sessionCreator.setOnce('Doctor specialty', this.userInfoTarget.dataset['doctorSpecialty']);
    }
    // set doctor name if exists(for patients role)
    if(this.userInfoTarget.dataset["role"] == 'patients'){
        sessionCreator.setOnce("Doctor name", this.userInfoTarget.dataset["doctorName"]);
      }
    amplitude.identify(sessionCreator);
    // track login if session just started
    if(this.userInfoTarget.dataset['justLoggedIn'] == 'true'){
      amplitude.track('User login');
    }
  }

  doctorDeletesVideo() {
    amplitude.track('Doctor deletes video');
  }

  doctorUploadsVideo() {
    amplitude.track('Doctor uploads video');
  }

  doctorDeletesDocument() {
    amplitude.track('Doctor deletes document');
  }

  doctorUploadsDocument() {
    amplitude.track('Doctor uploads document');
  }

  editUserProfile() {
    amplitude.track('User clicks profile menu');
  }

  saveUserProfile() {
    amplitude.track('User updates profile');
  }

  destroyUserSession() {
    amplitude.track('User logout').promise.then(function(result) {
      amplitude.reset();
    });
  }

  userClosesWindow() {
    amplitude.track('User closes or refresh window');
  }

  userClicksMenuOption(event) {
    amplitude.track('User clicks menu option', {
      menu: event.target.dataset['menu'],
      option: event.target.dataset['option']
    });
  }

  doctorSearchForMedicament() {
    amplitude.track('Doctor searches for medicament', {
      'Search term': this.searchTermTarget.value
    });
  }

  patientSearchForMedicament() {
    amplitude.track('Patient searches for medicament', {
      'Search term': this.searchTermTarget.value
    });
  }

  publicSearchForMedicament() {
    amplitude.track('Public user searches for medicament', {
      'Search term': this.searchTermTarget.value
    });
  }

  doctorGetMedicamentResults() {
    amplitude.track('Doctor gets medicament results', {
      'Search term': this.element.dataset['searchTerm'],
      'Quantity': this.element.dataset['quantity']
    });
  }

  patientGetMedicamentResults() {
    amplitude.track('Patient gets medicament results', {
      'Search term': this.element.dataset['searchTerm'],
      'Quantity': this.element.dataset['quantity']
    });
  }

  publicGetMedicamentResults() {
    amplitude.track('Public user gets medicament results', {
      'Search term': this.element.dataset['searchTerm'],
      'Quantity': this.element.dataset['quantity']
    });
  }

  userSearchForCampaign() {
    amplitude.track('User searches for product sample', {
      'Search term': this.searchTermTarget.value
    });
  }

  userSearchForPatientProgram() {
    amplitude.track('User searches for patient program', {
      'Search term': this.searchTermTarget.value
    });
  }

  toggleFavorite(event) {
    if(event.submitter.dataset['newFavorite'] == 'true'){
      amplitude.track('Doctor adds new favorite ', {
        'Type': event.submitter.dataset['template'],
        'Id': event.submitter.dataset['objectId'],
        'Reference': event.submitter.dataset['objectDescription'],
      });
    }else{
      amplitude.track('Doctor removes favorite ', {
        'Type': event.submitter.dataset['template'],
        'Id': event.submitter.dataset['objectId'],
        'Reference': event.submitter.dataset['objectDescription'],
      });
    }
  }

  tabMedicamentPrice(event) {
    amplitude.track('Click tab medicament price', {
      'product_id': event.target.dataset['productId'],
      'product_name': event.target.dataset['productName'],
      'product_laboratory': event.target.dataset['productLaboratory']
    });
  }

  pharmacyLinkMedicament(event) {
    amplitude.track('Click on pharmacy link to medicament', {
      'product_id': event.target.dataset['productId'],
      'product_name': event.target.dataset['productName'],
      'product_laboratory': event.target.dataset['productLaboratory'],
      'product_url': event.target.dataset['productUrl']
    });
  }

  tabPatientProgram(event) {
    amplitude.track('Click tab patient program', {
      'product_id': event.target.dataset['productId'],
      'product_name': event.target.dataset['productName'],
      'product_laboratory': event.target.dataset['productLaboratory']
    });
  }

  patientProgramFile(event) {
    amplitude.track('Click on patient program link to file', {
      'product_id': event.target.dataset['productId'],
      'program_name': event.target.dataset['programName'],
      'program_url_file': event.target.dataset['programUrlFile']
    });
  }

  frequentQuestions(event) {
    amplitude.track('Click on frequent questions', {
    });
  }

  informationChileanSistem(event) {
    amplitude.track('Click on information chilean sistem', {
    });
  }

}
