import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  validateUrl(event) {
    event.currentTarget.classList.remove('is-valid', 'is-invalid')
    if (event.currentTarget.value.trim().length == 0){
      return true
    }
    if (this.isValidHttpUrl(event.currentTarget.value)){
      event.currentTarget.classList.add('is-valid')
    }else{
      event.currentTarget.classList.add('is-invalid')
    }
  }

  isValidHttpUrl(url) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );
    const f_valid = pattern.test(url)
    var s_valid = false
    try {
      new URL(url)
      s_valid = true
    } catch (err) {
    }
    return (f_valid && s_valid)
  }

  removeInvalidData(){
    var invalid = document.getElementsByClassName("is-invalid")
    for (var i = 0; i < invalid.length; i++) {
      invalid[i].value = ""
    }
  }
}
