import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ["card"];

  connect() {
    this.subscription = consumer.subscriptions.create(
      {
        channel: "DoctorHandshakesChannel",
        id: this.cardTarget.dataset['doctorId'],
      },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    );
  }

  _connected() {}

  _disconnected() {}

  _received(data) {
    const element = this.cardTarget
    element.innerHTML = data
  }
}
