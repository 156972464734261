import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['messages']

  connect() {
  }

  push(event) {
    history.pushState({}, "", event.target.attributes.href.value);
  }

}
