import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

var modal = null;
export default class extends Controller {
  static targets = ['alert', 'modal']

  connect() {
    if(this.hasAlertTarget){
      var delay = 5000;
      var fadeTarget = this.alertTarget;
      setTimeout(
        function(){
          fadeTarget.classList.add('dismissed')
        }
        , delay
      );
    }
    document.addEventListener('turbo:submit-end', function(evt) {
      if(evt.target.attributes.action.value == '/feedback' ){
        modal.hide();
      }
    })
  }

  openModal() {
    modal = new bootstrap.Modal(this.modalTarget, {});
    modal.show();
  }

  copyToClipboard(evt) {
    navigator.clipboard.writeText(evt.target.dataset['contents']);
  }

  openMenu() {
    var body = document.querySelector('body')
    var bg = document.createElement('div')
    bg.id = "menu-bg"
    bg.className = 'd-block d-sm-none modal-backdrop fade show'
    bg.style = "background-color: rgba(40, 46, 85, 0.7); display: block;"
    bg.setAttribute("data-action","click->layout#closeMenuBg")
    body.appendChild(bg)
  }

  closeMenu() {
    document.getElementById('menu-bg').remove()
  }

  closeMenuBg(){
    document.getElementById("btnCloseMenu").click()
  }
}
