import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['messages']

  connect() {
    const board = this.messagesTarget;
    board.scrollTop = board.scrollHeight;
  }

}
